import React, { useState } from "react";
import FeedbackList from "../components/FeedbackList";
import FeedbackData from "../FeedbackData.js";
import FeedbackContext from "../context/FeedbackContext.jsx";
import FeedbackStats from "../components/FeedbackStats.jsx";
import FeedbackForm from "../components/FeedbackForm.jsx";
import AboutIconLink from "../components/AboutIconLink.jsx";

const HomePage = () => {
  return (
    <div className="container">
      <FeedbackForm />
      <FeedbackStats />
      <FeedbackList />
      <AboutIconLink />
    </div>
  );
};

export default HomePage;
