const RatingSelect = ({ rating, select }) => {
  const handleChange = (e) => {
    select(+e.target.value);
  };

  return (
    <ul className="rating">
      {Array.from({ length: 10 }, (_, i) => i + 1).map((num) => {
        return (
          <li key={num}>
            <input
              type="radio"
              id={`num${num}`}
              name="rating"
              value={num}
              onChange={handleChange}
              checked={rating === num}
            />
            <label htmlFor={`num${num}`}>{num}</label>
          </li>
        );
      })}
    </ul>
  );
};

export default RatingSelect;
