import { createContext, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";

const FeedbackContext = createContext();

export const FeedbackProvider = ({ children }) => {
  const [feedback, setFeedback] = useState([]);

  const [feedbackEdit, setFeedbackEdit] = useState({
    item: {},
    edit: false,
  });

  // Delete feedback
  const deleteFeedback = (id) => {
    if (window.confirm("Are you sure you want to delete this feedback?")) {
      setFeedback((prev) => prev.filter((feedback) => feedback.id !== id));
      axios.delete(`http://localhost:3000/feedbacks/${id}`);
    }
  };

  // Add feedback
  const addFeedback = (newFeedback) => {
    newFeedback.id = uuidv4();
    setFeedback([newFeedback, ...feedback]);
    axios.post("http://localhost:3000/feedbacks", newFeedback);
  };

  // Update feedback
  const updateFeedback = (id, updItem) => {
    setFeedback(
      feedback.map((item) => (item.id === id ? { ...item, ...updItem } : item))
    );
    editFeedback({ item: {}, edit: false });
    axios.put(`http://localhost:3000/feedbacks/${id}`, updItem);
  };

  // Edit feedback
  const editFeedback = (item) => {
    setFeedbackEdit({ item, edit: true });
  };

  useEffect(() => {
    const fetchData = async () => {
      const feedbacks = await axios.get("http://localhost:3000/feedbacks");
      setFeedback(feedbacks.data);
    };
    fetchData();
  }, []);

  return (
    <FeedbackContext.Provider
      value={{
        feedback,
        deleteFeedback,
        addFeedback,
        feedbackEdit,
        editFeedback,
        updateFeedback,
      }}
    >
      {children}
    </FeedbackContext.Provider>
  );
};

export default FeedbackContext;
