import React from 'react'
import { createRoot } from 'react-dom/client'
import { createBrowserRouter, createRoutesFromElements, RouterProvider, Route, createHashRouter } from 'react-router-dom'
import './index.css'
import App from './App'
import AboutPage from './pages/AboutPage'
import HomePage from './pages/HomePage'

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path='/' element={<App />}>
      <Route index={true} path='/' element={<HomePage />}></Route>
      <Route path='about' element={<AboutPage />}></Route>
    </Route>
  )
)
const root = createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    {/* <RouterProvider router={router}></RouterProvider> */}
    <App />
  </React.StrictMode>
)